<template lang="pug">
  div#app(:class="[layoutClassName]"  ref="app")
    div#header
      comp-header(v-if="!$isMobile")
      comp-header-mobile(v-if="$isMobile")
    div#content
      div#player
        comp-player(v-if="!$isMobile" ref="player")
        comp-player-mobile(v-if="$isMobile" ref="player")
      div#sign(v-if="sign_flag")
          h3 请签到
          p
            span.times {{starttime}}
            span.unit s
          a.btn(@click="signUp") {{title}}
      div#message
        comp-message(ref="message" v-if="storeAPPID>0 "
          :isMobileVerticalLayout="isMobileVerticalLayout"
          :isMobileHorizontalLayout="isMobileHorizontalLayout")
</template>

<script>
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import compHeader from '@/components/comp-header/index';
import compHeaderMobile from '@/components/comp-header/index-mobile';
import compPlayer from '@/components/comp-player/index';
import compPlayerMobile from '@/components/comp-player/index-mobile';
import compMessage from '@/components/comp-message';
import layout from './layout.js';
import {
  SET_SDK_APP_ID,
  SET_USER_SIG,
  SET_PLAYER_DOMAIN,
  SET_ROOM_ID,
  SET_ROOM_NAME,
  UPDATE_USER_INFO,
  SET_ANCHOR_USER_ID,
  SET_IS_SUPPORT_WEBRTC,
  UPDATE_LINE_TYPE,
  UPDATE_LIVE_STAGE,
  SET_IS_VISITOR,
} from '@/constants/mutation-types';
import {
  LINE_TYPE,
  LIVE_STAGE,
} from '@/constants/room';
import {
  // sdkAppId,
  expireTime,
  // secretKey,
  playerDomain,
  userInfo,
  roomInfo,
  anchorUserInfo,
} from '@/config/basic-info-config';
import { mapState } from 'vuex';
import TRTC from 'trtc-js-sdk';
export default {
  name: 'App',
  mixins: [layout],
  data() {
    return {
      goodsId: 0,
      anchorUserId: '', // 主讲老师id
      backUrl: '',
      sdkAppId: 0,
      secretKey: '',
      title: '签到',
      timer: null,
      sign_flag: false,
      signId: 0,
      starttime: 30,
    };
  },
  computed: {
    ...mapState({
      userInfo: 'userInfo',
      roomId: 'roomId',
      storeAPPID: 'sdkAppId',
      isVisitor: 'isVisitor',
    }),
  },
  components: {
    compHeader,
    compHeaderMobile,
    compPlayer,
    compPlayerMobile,
    compMessage,
  },
  methods: {
    // 处理是否支持 webRTC
    async handleSupportWebRTC() {
      const supportResult = await TRTC.checkSystemRequirements();
      const { isWebRTCSupported, isH264DecodeSupported } = supportResult.detail;
      if (!isWebRTCSupported || !isH264DecodeSupported) {
        this.$store.commit(SET_IS_SUPPORT_WEBRTC, false);
        this.$store.commit(UPDATE_LINE_TYPE, LINE_TYPE.CDN);
      }
    },
    // 处理页面数据
    handlePlayerInfo() {
      if (this.sdkAppId === '' || this.secretKey === '') {
        this.back('没有配置直播参数，跳转至原网站?');
        // alert(`${this.$t('basic.Please configure your SDKAPPID')}\r\n\r\nconfig/basic-info-config.js`);

        return false;
      }
      const generator = new LibGenerateTestUserSig(this.sdkAppId, this.secretKey, expireTime);
      const userSig = generator.genTestUserSig(userInfo.userId);
      // 注意：请优先设置 sdkAppId 信息
      this.$store.commit(SET_SDK_APP_ID, this.sdkAppId);
      this.$store.commit(SET_USER_SIG, userSig);
      this.$store.commit(SET_PLAYER_DOMAIN, playerDomain);
      this.$store.commit(SET_ROOM_ID, roomInfo.roomId);
      this.$store.commit(SET_ROOM_NAME, roomInfo.roomName);
      this.$store.commit(SET_ANCHOR_USER_ID, anchorUserInfo.userId);
      this.$store.commit(UPDATE_USER_INFO, {
        userId: userInfo.userId,
        userName: userInfo.userName,
        userAvatar: userInfo.userAvatar,
        goodsId: this.goodsId,
      });
      this.$store.commit(SET_ANCHOR_USER_ID, this.anchorUserId);
    },
    // 退出直播间
    async handleExit() {
      // todo: 处理退出直播间
      window.location.href = this.backUrl;
    },
    // 退出登录
    async handleLogout() {
      // todo: 处理退出登录
      window.location.href = this.backUrl;
    },
    initVistor() {
      // this.back('您还没有登录，请先登录！');
      let _this = this;
      _this.$store.commit(SET_IS_VISITOR, true);
      this.$confirm('您没有登录，是否以听课身份继续观看？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        console.log('init');
      })
        .catch(() => {
          window.location.href = _this.backUrl;
        });
    },
    async getUserInfo() {
      const _this = this;
      if (this.$cookies.get('Authentication') == null) {
        this.initVistor();
        return false;
      }
      // 获取用户信息
      const res = await this.$api.user.getUserInfo({});
      if (res.errorCode == '0000') {
        // console.log(res);
        userInfo.userId = res.data.userId;
        userInfo.userName = res.data.realName;
        if (res.data.headUrl) {
          userInfo.userAvatar = res.data.headUrl;
        }
        return true;
      }
      _this.initVistor();
      return false;
    },
    getQueryString(name) {
      // const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    async getAreaData(domain) {
      // 获取站点信息
      let dostr = domain;
      dostr = dostr.replace('net.cn', 'cn');
      // dostr = dostr.replace('net', 'cn');
      // dostr = dostr.replace('com.cn', 'cn');
      // dostr = dostr.replace('com', 'cn');
      await this.$api.user.getAreaData({ domain: dostr }).then((res) => {
        if (res.errorCode == '0000') {
          console.log(res);
          let baseArr = domain.split('.');
          let first = baseArr.shift();
          baseArr.unshift(first, 'api');
          let api = `${baseArr.join('.')}/api.php?s=web`;
          this.$baseUrl = domain;
          this.$handleUrl.axios.defaults.baseURL = `${window.location.protocol}//${api}`;
        } else {
          this.error(res.errorMsg);
          this.back('站点不存在，跳转至原网站?');
        }
      })
        .catch(() => {
        });
    },
    async getCourseInfo(courseId) {
      // 获取课程信息
      console.log('获取课程信息');
      await this.$api.user.getLiveInfo({ courseId, type: 2 }).then((res) => {
        if (res.errorCode == '0000') {
          if (res.data.livestatus == 0) {
            this.$store.commit(UPDATE_LIVE_STAGE, LIVE_STAGE.NOT_STARTED);
            this.back('直播暂未开始，跳转至原网站？');
          } else if (res.data.livestatus == 2) {
            this.$store.commit(UPDATE_LIVE_STAGE, LIVE_STAGE.ENDED);
            this.back('直播已结束');
          } else {
            this.goodsId = courseId;
            this.anchorUserId = res.data.teacherId;
            roomInfo.roomId = parseInt(courseId.substring(courseId.length - 7));
            roomInfo.roomName = res.data.name;
          }
        } else {
          this.error(res.errorMsg);
          this.back('站点不存在，跳转至原网站?');
        }
      })
        .catch(() => {
        });
    },
    async getTencent() {
      const res = await this.$api.seetting.getTencent({});
      this.sdkAppId = parseInt(res.data.SDKAppID);
      this.secretKey = res.data.secretKey;
      this.$store.commit(SET_SDK_APP_ID, this.sdkAppId);
      this.$store.commit(SET_USER_SIG, this.secretKey);
    },
    back(msg) {
      // 跳转至原网站
      let _this = this;
      this.$alert(msg, '提示', {}).then(() => {
        window.location.href = _this.backUrl;
      })
        .catch(() => {});
    },
    setAxiosBaseUrl() {
      let baseArr = this.$baseUrl.split('.');
      let first = baseArr.shift();
      baseArr.unshift(first, 'api');
      let api = `${baseArr.join('.')}/api.php?s=web`;
      this.$handleUrl.axios.defaults.baseURL = `${window.location.protocol}//${api}`;
    },
    // 获取签到
    // async getSign() {
    //     if (this.sign_flag || this.goodsId <= 0) {
    //         return ;
    //     }
    //     let _this = this;
    //     await this.$api.user.getSign({courseId: this.goodsId}).then(res => {
    //         console.log(res);
    //         if (res.errorCode == '0000') {
    //
    //         }
    //     }).catch(() => {
    //     });
    // },
    showCallDialog(params) {
      let _this = this;
      _this.sign_flag = true;
      _this.starttime = 90;
      _this.signId = params.signId;
      let interval = setInterval(() => {
        if (_this.starttime == 0) {
          clearInterval(interval);
          _this.starttime = 90;
          _this.sign_flag = false;
          return;
        }
        _this.starttime = _this.starttime - 1;
      }, 1000);
    },
    // 签到
    async signUp() {
      await this.$api.user.signUp({ signId: this.signId }).then((res) => {
        console.log(res);
        if (res.errorCode == '0000') {
          this.sign_flag = false;
          this.$message.success('签到成功');
        }
      })
        .catch(() => {
        });
    },
    // 保存播放进度
    async saveLeaningRecord() {
      console.log('开始记录');
      let _this = this;
      if (_this.goodsId > 0) {
        await this.$api.user.saveLeaningRecord({ courseId: this.goodsId }).then((res) => {
          console.log('保存学习记录');
          if (res.errorCode == '0000') {
            if (res.data == 2) {
              this.timer = null;
              clearInterval(this.timer);
              this.back('直播已结束');
            }
          }
        })
          .catch(() => {
          });
      }
    },
  },
  async created() {
    this.$store.commit(SET_PLAYER_DOMAIN, playerDomain);
    this.setAxiosBaseUrl();
    this.backUrl = this.getQueryString('backurl');
    if (!this.backUrl || this.backUrl == '') {
      this.backUrl = `http://${this.$baseUrl}`;
    }
    let domain = this.getQueryString('domain');
    if (domain) {
      await this.getAreaData(domain);
    }
    let token = this.getQueryString('token');
    if (token) {
      this.$cookies.set('Authentication', token);
    }
    const infostate = await this.getUserInfo();
    if (infostate == false && 0) {
      return false;
    }

    let courseId = this.getQueryString('courseId');
    if (!courseId) {
      // 课程编号不存在，跳转
      this.back('课程编号不存在，跳转至原网站?');
      // window.location.href=window.location.protocol+"//"+this.$baseUrl;
    }
    await this.getCourseInfo(courseId);
    await this.getTencent();
    this.handlePlayerInfo();
    // this.$store.commit(SET_IS_VISITOR, false);
    this.$store.commit(UPDATE_LINE_TYPE, LINE_TYPE.CDN);
    // 判断是否支持 webrtc, 不支持 webrtc 的网页采用【线路三：标准直播】播放，且不可切换线路
    this.handleSupportWebRTC();
    // 处理页面数据

    let _this = this;
    if (this.isVisitor === false) {
      this.timer = setInterval(() => {
        // _this.getSign();
        _this.saveLeaningRecord();
      }, 30000);
    }


    // 处理监听事件
    this.$eventBus.$on('exit', this.handleExit);
    this.$eventBus.$on('logout', this.handleLogout);
    this.$eventBus.$on('tim:Call', this.showCallDialog);
    this.$eventBus.$on('showLoginCard', this.handleShowLoginCard);
  },
  beforeDestroy() {
    this.$eventBus.$off('exit', this.handleExit);
    this.$eventBus.$off('logout', this.handleLogout);
    this.$eventBus.$off('tim:Call', this.showCallDialog);
    this.$eventBus.$off('showLoginCard', this.handleShowLoginCard);
  },
};
</script>

<style lang="stylus">
@import '~assets/style/black-element-ui.styl';

.el-message-box__wrapper
  .el-message-box
    width unset

#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  width 100%
  height 100%
  position relative
  text-align center
  overflow auto
  /*#player*/
  #sign
    position absolute
    /*top:20px*/
    width 200px
    height 200px
    z-index 99996
    left calc(50% - 100px)
    color #000000
    padding 20px
    border-radius 5px
    background-color #FFFFFF
    h3
      line-height 40px
    p
      font-size 25px
      .times
        color red
    .btn
      display inline-block
      background-color #006EFF
      padding 10px 20px
      color #FFFFFF
      border-radius 2px
      z-index 99997
      margin auto
      margin-top 30px

// PC页面布局
#app.app-layout
  background-color $backgroundColor
  color $fontColor
  #header
    width 100%
    height 52px
    background-color $themeColor
  #content
    width 100%
    position absolute
    left 0
    bottom 0
    top 52px
    display flex
    #player
      height 100%
      flex-grow 1
      background-color $backgroundColor
    #message
      width 30%
      min-width 300px
      max-width 406px
      height 100%
      background-color $IMThemeColor

// 移动端竖屏布局
#app.mobile-vertical-layout
  background-color $IMThemeColor
  color $fontColor
  display flex
  flex-direction column

  #header
    width 100%
    max-height 130px
  #content
    flex-grow 1
    display flex
    flex-direction column
    #player
      width 100%
      height 250px
      background-color $backgroundColor
    #message
      background-color $IMThemeColor
      flex-grow 1
      position relative

// 移动端横屏布局
#app.mobile-horizontal-layout
  color $fontColor
  #header
    width 100%
    position absolute
    top 0
    left 0
    z-index 10
    transform translateZ(100px)
  #content
    width 100%
    height 100%
    position relative
    #player
      width 100%
      height 100%
      background-color $backgroundColor
    #message
      position absolute
      bottom 0
      left 0
      width 100%
      height 70%
      pointer-events none
      transform translateZ(100px)
</style>
