import { fetch} from '@/utils/http.js';
/**
 * 获取配置
 */
function getTencent(param) {
    return new Promise((resolve, reject) => {
        fetch('/lives/getTencent', {
            params: param
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });
    });
}

export default {
	getTencent,
};
