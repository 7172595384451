import axios from 'axios';
import VueCookies from 'vue-cookies';
axios.defaults.timeout = 50000;
axios.defaults.baseURL = '';
// axios.defaults.baseURL = '/api.php?s=web';
axios.defaults.withCredentials = false;
/**
 *  添加请求头(带HTTP时不添加)
 *
 * @param {*} url 请求地址
 * @returns
 */
export function handleUrl(url) {
	if (url.indexOf('http') == -1) {
		url = axios.defaults.baseURL + url;
	}

	return url;
}
// http request 拦截器
axios.interceptors.request.use(
	config => {
		config.url = handleUrl(config.url);
		config.headers = {
			'Content-Type': 'application/x-www-form-urlencoded',
			// "Access-Control-Allow-Origin":'*',
		};
		if (VueCookies.get('Authentication') != null) { // 判断是否存在token，如果存在的话，则每个http header都加上token
			if (config.method == "get") {
				config.params.Authentication = VueCookies.get('Authentication');
			} else {
				config.data = "${config.data}Authentication=${VueCookies.get('Authentication')}";
				// config.data = config.data + "Authentication=" + VueCookies.get('Authentication');
			}
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);
// http response 拦截器
axios.interceptors.response.use(
	response => {
		if (response.data.errorCode != '0000') {
			if (response.data.errorCode == '0004') {
				VueCookies.remove('Authentication'); // 移除登录凭证
				VueCookies.remove('authority'); // 移除用户权限
				// this.$router.push({path:'/testDemo',query:{setid:123456}});
			}
		}

		return response;
	},
	error => {
		return Promise.reject(error);
	}
);
/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, params = {}) {
	params.params.remoteType = 'operationsystem';
	return new Promise((resolve, reject) => {
		axios.get(url, params)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				reject(err);
			});
	});
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
	return new Promise((resolve, reject) => {
		axios.patch(url, data)
			.then(response => {
				resolve(response.data);
			}, err => {
				reject(err);
			});
	});
}
export default {
	axios
};
